<template>
  <div>
    <div class="row justify-content-end">
      <div class="col">
        userOnline:

        {{ userOnline }}
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'users-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouvel utilisateur
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Nom</th>
          <th scope="col">Nom d'utilisateur</th>
          <th scope="col">Rôle</th>
          <th scope="col">Vu récemment</th>
          <th scope="col">Accès</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ user.reference }}
          </td>
          <td>
            <span v-if="user.employee">
              {{ user.employee.fullName }}
            </span>
          </td>
          <td>
            {{ user.username }}
          </td>
          <td>
            <span v-if="user.role">
              {{ user.role.fullName }}
            </span>
          </td>

          <td>
            <span
              class="p-0 m-0"
              :style="{
                fontSize: '40px !important',
                fontWeight: 'bolder !important',
              }"
              :class="
                user.last_seen_minutes_ago < 30 && user.last_seen_seconds_ago != -1
                  ? 'text-success'
                  : 'text-secondary'
              "
            >
              .
            </span>
            <span v-if="user.last_seen_seconds_ago == -1" class="text-secondary">
              Inactif
            </span >
            <span v-else-if="user.last_seen_seconds_ago == 0" class="text-success">
              Actif maintenant
            </span >
            <span
              v-else-if="user.last_seen_seconds_ago < 60"
              class="text-success"
            >
              active il y a {{ user.last_seen_seconds_ago }} secondes
            </span>
            <span
              v-else-if="user.last_seen_minutes_ago < 60"
              :class="
                user.last_seen_minutes_ago < 30
                  ? 'text-success'
                  : 'text-secondary'
              "
            >
              active il y a {{ user.last_seen_minutes_ago }} minutes
            </span>
            <span
              v-else-if="user.last_seen_hours_ago < 24"
              class="text-secondary"
            >
              active il y a{{ user.last_seen_hours_ago }} heures
            </span>
            <span v-else class="text-secondary">
              active il y a {{ user.last_seen_days_ago }} jours
            </span>
          </td>
          <td>
            <span
              v-if="user.access"
              class="bg-success rounded px-2 py-1 text-white"
            >
              Autorisé
            </span>
            <span
              v-else-if="!user.access"
              class="bg-danger rounded px-2 py-1 text-white"
            >
              Bloqué
            </span>
          </td>
          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'users-edit',
                  params: { reference: user.reference },
                })
              "
              class="btn text-secondary"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button class="btn" @click="remove(user)">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("user", {
      users: "getAll",
      userOnline: "getUserOnline",
    }),
  },
  mounted() {
    this.$store.dispatch("user/getAll");
    this.$store.dispatch("user/fetchUserStatus");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("user/search", value);
    },
    async refresh() {
      await this.$store.dispatch("user/getAll");
      await this.$store.dispatch("user/fetchUserStatus");
    },
    async remove(data) {
      let user = data;
      await this.$confirm({
        message: "Vous voulez supprimer l'utilisateur  " + user.name,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("user/destroy", user.reference);
          }
        },
      });
    },
  },
};
</script>
