var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col"},[_vm._v(" userOnline: "+_vm._s(_vm.userOnline)+" ")]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"}),_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'users-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer un nouvel utilisateur ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.users),function(user,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(user.reference)+" ")]),_c('td',[(user.employee)?_c('span',[_vm._v(" "+_vm._s(user.employee.fullName)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(user.username)+" ")]),_c('td',[(user.role)?_c('span',[_vm._v(" "+_vm._s(user.role.fullName)+" ")]):_vm._e()]),_c('td',[_c('span',{staticClass:"p-0 m-0",class:user.last_seen_minutes_ago < 30 && user.last_seen_seconds_ago != -1
                ? 'text-success'
                : 'text-secondary',style:({
              fontSize: '40px !important',
              fontWeight: 'bolder !important',
            })},[_vm._v(" . ")]),(user.last_seen_seconds_ago == -1)?_c('span',{staticClass:"text-secondary"},[_vm._v(" Inactif ")]):(user.last_seen_seconds_ago == 0)?_c('span',{staticClass:"text-success"},[_vm._v(" Actif maintenant ")]):(user.last_seen_seconds_ago < 60)?_c('span',{staticClass:"text-success"},[_vm._v(" active il y a "+_vm._s(user.last_seen_seconds_ago)+" secondes ")]):(user.last_seen_minutes_ago < 60)?_c('span',{class:user.last_seen_minutes_ago < 30
                ? 'text-success'
                : 'text-secondary'},[_vm._v(" active il y a "+_vm._s(user.last_seen_minutes_ago)+" minutes ")]):(user.last_seen_hours_ago < 24)?_c('span',{staticClass:"text-secondary"},[_vm._v(" active il y a"+_vm._s(user.last_seen_hours_ago)+" heures ")]):_c('span',{staticClass:"text-secondary"},[_vm._v(" active il y a "+_vm._s(user.last_seen_days_ago)+" jours ")])]),_c('td',[(user.access)?_c('span',{staticClass:"bg-success rounded px-2 py-1 text-white"},[_vm._v(" Autorisé ")]):(!user.access)?_c('span',{staticClass:"bg-danger rounded px-2 py-1 text-white"},[_vm._v(" Bloqué ")]):_vm._e()]),_c('td',{staticClass:"d-flex"},[_c('button',{staticClass:"btn text-secondary",on:{"click":function($event){return _vm.$router.push({
                name: 'users-edit',
                params: { reference: user.reference },
              })}}},[_c('i',{staticClass:"bi bi-pencil-square"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(user)}}},[_c('i',{staticClass:"bi bi-trash text-danger"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nom")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nom d'utilisateur")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Rôle")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Vu récemment")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Accès")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }